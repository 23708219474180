import { useNavigate } from 'react-router-dom';
import Main from "../commons/Main";
import ExperimentList from '../components/experimentLists';
import LoadingData from "../data/loadData";
import Greeting from "../components/GreetingMessage";


import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function Home(){
    
    const navigate = useNavigate();

    const isAuthenticated = sessionStorage.getItem('isAuthenticated');
    console.log('IS-AUTH', isAuthenticated)
    let HomeContent =
        <Container className={'mt-4 pt-4'}fluid>
            <Greeting/>
            <ExperimentList/>
        </Container>

    if (isAuthenticated === 'false') {
        console.log('imhere');
        return (
            navigate('/')
        );
    }


return (
        <Main children={HomeContent}/>
)

}

export default Home;